<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="stock">
        <div class="stock_box">
            <div class="stock_box_left">
                <el-date-picker
                    v-model="time"
                    class="purchasing_box_left_sj"
                    @change="handleDate"
                    type="month"
                    value-format="yyyy-MM"
                >
                </el-date-picker>
                <!-- <div class="xuan_chanpin">
                    产品
                    <el-tooltip class="item" effect="dark" :content="dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')" placement="top-start">
                        <p @click="dianji_xuanze_chanpin">{{dangqian_xuanze_chanpin.length!=0?(dangqian_xuanze_chanpin.map(item=>item.prod_name).join(',')):'请选择'}}</p>
                    </el-tooltip>
                </div> -->
                <ul class="sqe_tit">
                    <li style="margin-right: 0;" v-for="(i,index) in anniu_list" :class="i.id==1?'zise':''" :key="index" @click="dianji_xuanze(i)">
                        {{i.name}}
                    </li>
                </ul>
                <div class="xuanze_zhanshi">
                    <p @click="zong_mingxi_num=0">
                        <img :src="zong_mingxi_num==0?require('../../../assets/me/xuan_zi.png'):require('../../../assets/me/weixuan_zi.png')" alt="">
                        总账
                    </p>
                    <p @click="zong_mingxi_num=1">
                        <img :src="zong_mingxi_num==1?require('../../../assets/me/xuan_zi.png'):require('../../../assets/me/weixuan_zi.png')" alt="">
                        明细账
                    </p>
                </div>
                <ul class="sqe_tit">
                    <li v-for="i in zhanshi_list" :class="i.id==anniu_zhanshi?'zise':''" :key="i.id" @click="anniu_zhanshi=i.id">
                        {{i.name}}
                    </li>
                </ul>
                <div class="daochu" @click="exportExcel">导出</div>
                <!-- <p class="sqe_btn" @click="qvLing">
                    <img :src="shifou_quling?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                    去零
                </p> -->
            </div>
            <div style="display: flex;">
              <div @click="chooseC" style="padding-right: 20px;">筛选产品</div>
              <div @click="chooseCk" v-if="zong_mingxi_num == 1" style="padding-right: 20px;">筛选仓库</div>
              <div>单位:元</div>
            </div>
            <!-- <div class="stock_box_right">
                <i class="el-icon-search"></i>
                <el-select placeholder="选择产品" v-model="selectData">
                  <el-option
                    v-for="item in options"
                    :label="item.name"
                    :value="item.name"
                    :key="item.name"
                  ></el-option>
                </el-select>
            </div> -->
        </div>
        <div class="gongying_shang_table">
            <el-table
                ref="gongying_shang_ref"
                :data="gongying_shang_list"
                :cell-style="liebiao"
                :header-cell-style="biaotou"
                tooltip-effect="dark"
                height="6.5rem"
                @row-click='hangdian'
                style="width: 100%;font-size: 14px">
                <el-table-column v-for="(i,index) in biao1_tit" :key="index"
                    :prop="i.con"
                    :label="i.name"
                    min-width="150"
                    >
                    <template slot-scope="props">
                        <span v-if="i.con!='prod_pic'">
                            {{props.row[i.con]}}
                        </span>
                        <img style="width:0.9rem;height:0.9rem;" v-if="i.con=='prod_pic'" :src="props.row[i.con]" alt="">
                        <span v-if="i.con!='prod_pic'&&(props.row[i.con]==undefined||props.row[i.con].length==0)">—</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="本期增加"
                    >
                    <el-table-column
                        label="数量"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==2"
                        >
                        <template slot-scope="props">
                            {{props.row.qty_add | hangdleQty}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="单价"
                        v-if="anniu_zhanshi==4"
                        >
                        <template slot-scope="props">
                            {{props.row.price_unit_add}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==3"
                        >
                        <template slot-scope="props">
                            {{props.row.amt_add}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="本期减少"
                    >
                    <el-table-column
                        label="数量"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==2"
                        >
                        <template slot-scope="props">
                            {{props.row.qty_sub | hangdleQty}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="单价"
                        v-if="anniu_zhanshi==4"
                        >
                        <template slot-scope="props">
                            {{props.row.price_unit_sub}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==3"
                        >
                        <template slot-scope="props">
                            {{props.row.amt_sub}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="余额"
                    >
                    <el-table-column
                        label="数量"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==2"
                        >
                        <template slot-scope="props">
                            {{props.row.qty_end | hangdleQty}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="单价"
                        v-if="anniu_zhanshi==4"
                        >
                        <template slot-scope="props">
                            {{props.row.price_unit_end}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额"
                        v-if="anniu_zhanshi==4||anniu_zhanshi==3"
                        >
                        <template slot-scope="props">
                            {{props.row.amt_end}}
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <div class="opt">
          <div v-for="(item, index) in prodNames" :key="index" class="btn_chan" @click="hangdClick(item)">
            {{item.prod_name}}
          </div>
        </div>
        <div class="beijing" @click='youce_xiangqing=false' v-if="youce_xiangqing">
            <div class="youce_box" @click.stop >
                <div class="yanjing">
                    <table>
                        <tr>
                            <td>成交时间</td>
                            <td>成交金额({{wenzi_jine_zhuanhua(right_zhanshi.chengjiao_jine)}})</td>
                            <td>退回次数</td>
                            <td>状态</td>
                        </tr>
                        <tr>
                            <td>{{right_zhanshi.chengjiao_sj}}</td>
                            <td>{{jine_zhuanhua(right_zhanshi.chengjiao_jine)}}</td>
                            <td>{{right_zhanshi.tuihui_cishu}}</td>
                            <td>{{right_zhanshi.zhuangtai}}</td>
                        </tr>
                    </table>
                    <img src="../../../assets/me/eye.png" @click="dakai_danju(xiangxi_xinxi)" alt="">
                </div>
                <div class="youce_xiangxi_xinxi" v-for='(item,idx) in xiangxi_xinxi' :key="idx">
                    <!-- {{idx+1}}、 -->
                    <p class="xiangxi_tit" @click="xiangqing_zhankai=!xiangqing_zhankai">详细信息<i :class="xiangqing_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_zhankai" class="transition-box">

                            <ul>
								<li v-for="(i,index) in item.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
										<p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(it,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(it)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{it.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
										<p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(it)" v-for="(it,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{it.flow_desc}}:{{it.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(it,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in it.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'&&ite.img_url!=undefined&&ite.img_url.length!=0">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </transition>
                </div>
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxi">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_zhankai=!xiangqing_kehu_zhankai">客户操作日志<i :class="xiangqing_kehu_zhankai?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_zhankai" class="transition-box">
                            <ul>
                                <li v-for="(i,index) in zhanshi_lishi" :key="index">
                                    <p v-if="i.type=='1'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统分配给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='2'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.dept_name}}{{i.staff_name}}退回公海池；</p>
                                    <p v-if="i.type=='3'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}从{{i.dept_name}}冷冻仓激活到公海池；</p>
                                    <p v-if="i.type=='4'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}接收{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='5'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.staff_name}}拒绝{{i.operate_dept_name}}{{i.operate_user_name}}指派的{{i.cust_name}}；</p>
                                    <p v-if="i.type=='6'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入公海池；</p>
                                    <p v-if="i.type=='7'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}指派给{{i.dept_name}}{{i.staff_name}}；</p>
                                    <p v-if="i.type=='8'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}录入已分配；</p>
                                    <p v-if="i.type=='9'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被{{i.operate_dept_name}}{{i.operate_user_name}}修改；</p>
                                    <p v-if="i.type=='10'">{{index+1}}、{{i.operate_time}}-{{i.cust_name}}被系统录入</p>
                                    <p v-if="i.type=='11'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动退回</p>
                                    <p v-if="i.type=='12'">{{index+1}}、{{i.operate_time}}-{{i.dept_name}}{{i.cust_name}}被系统自动从公海池退回冷冻仓</p>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
                <!-- 附件下载多了个a注意，以后该这块需要注意 -->
                <div style="margin-top:0.3rem;" class="youce_xiangxi_xinxia">
                    <p class="xiangxi_tit" @click="xiangqing_kehu_fujian=!xiangqing_kehu_fujian">附件下载<i :class="xiangqing_kehu_fujian?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <transition name="el-zoom-in-top">
                        <div v-show="xiangqing_kehu_fujian" class="transition-box">
                            <p class="transition-box_tit">
                                选择归档号：
                            </p>
                            <p class='transition-box_tit_file'>
                                <span v-for="(i,index) in fujian_list" @click="dianji_guidang_hao(index)" :key="index"><img :src="zhanshi_guidang_hao==index?require('../../../assets/me/xuan_zi.png'):require('../../../assets/me/weixuan_zi.png')" alt="">{{i.file_no}}</span>
                            </p>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].con_annex!=undefined">
                                <p class="fujian_con_tit">合同附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].con_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].inv_annex!=undefined">
                                <p class="fujian_con_tit">发票附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].inv_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].rec_annex!=undefined">
                                <p class="fujian_con_tit">回款附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].rec_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="fujian_con" v-if="fujian_list.length!=0&&fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined">
                                <p class="fujian_con_tit">交付附件:</p>
                                <ul>
                                    <li v-for="(ita,ind) in fujian_list[zhanshi_guidang_hao].deliver_annex" :key="ind">
                                        <p>
                                            <img @click="ita.zhi=!ita.zhi" :src="ita.zhi?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                            {{ita.annex_name}}
                                        </p>
                                        <span @click="fujian_xiazai(ita)">下载</span>
                                    </li>
                                </ul>
                            </div>
                            <p class="fujian_foot" v-if="fujian_list.length!=0&&(fujian_list[zhanshi_guidang_hao].deliver_annex!=undefined||fujian_list[zhanshi_guidang_hao].con_annex!=undefined||fujian_list[zhanshi_guidang_hao].rec_annex!=undefined||fujian_list[zhanshi_guidang_hao].inv_annex!=undefined)">
                                <span @click="dianji_quanxuan_fujian">
                                    <img :src="fujian_quanxuan?require('../../../assets/okblue.png'):require('../../../assets/noccc.png')" alt="">
                                    全选
                                </span>
                                <span @click="dianji_piliang_xiazi">批量下载</span>
                            </p>
                            <p class="no_fujian" v-if="fujian_list.length==0||(fujian_list[zhanshi_guidang_hao].deliver_annex==undefined&&fujian_list[zhanshi_guidang_hao].con_annex==undefined&&fujian_list[zhanshi_guidang_hao].rec_annex==undefined&&fujian_list[zhanshi_guidang_hao].inv_annex==undefined)">该归档号下没有可以下载的附件</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <shaixuan ref="xuanze" @sousuo='sousuo_chanpin' @dalei='dianji_dalei' @shaixuan_queding='dianji_queding'></shaixuan>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="tree_guize" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tablea"
                        style="width: 100%"
                        @select = "xuanze_chanpin"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog title="筛选产品" :visible.sync="dialogTableVisible">
          <el-checkbox-group v-for="(item, index) in zong_chanpin_liebiao" :key="index" v-model="prod_name">
            <el-checkbox :label="item.prod_name" value="item.prod_name"></el-checkbox>
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogTableVisible = false">取 消</el-button>
            <el-button type="primary" @click="treeSure">确 定</el-button>
          </span>
        </el-dialog>
        <el-dialog title="筛选仓库" :visible.sync="dialogTableVisiblee">
          <el-checkbox-group v-for="(item, index) in cangku" :key="index" v-model="site_name">
            <el-checkbox :label="item.site_name" value="item.site_name"></el-checkbox>
          </el-checkbox-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogTableVisiblee = false">取 消</el-button>
            <el-button type="primary" @click="treeSuree">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
import shaixuan from '../../../components/shaixuan/Duoxuan'
import { query_all_prod_list_of_category, query_ent_prod_cat_list_tree, query_stock_mng_detail_prod_list, query_stock_mng_detail_list, query_category_info_list, query_prod_info_list, get_prod_list_like, query_stock_mng_list, query_stock_mng_detail_site_list } from '../../../api/api.js'

export default {
  name: 'stock',
  filters: {
    hangdleQty(val) {
      return Number(val).toFixed(2)
    }
  },
  data () {
    return {
      dialogTableVisible: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      site_name: [],
      btn_chanpin: [],
      indexZero: [],
      anniu_list: [
        {
          name: '盘点',
          id: '1'
        }
      ],
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',
      treeData: [],
      zhanshi_list: [
        {
          name: '数量',
          id: '2'
        },
        {
          name: '金额',
          id: '3'
        },
        {
          name: '全部',
          id: '4'
        }
      ],
      prodName: '',
      prodId: '',
      cangku: [],
      anniu_zhanshi: '4',
      dialogTableVisiblee: false,
      options: [],
      gongying_shang_list: [],
      gongying: [],
      prod_name: [],
      xuan_zhong: '',
      biao1_tit: [{
        name: '日期',
        con: 'date_stock'
      },
      {
        name: '图片',
        con: 'prod_pic'
      },
      {
        name: '产品',
        con: 'prod_name'
      },
      {
        name: '产品编号',
        con: 'prod_code'
      },
      {
        name: '类型',
        con: 'cat_name'
      },
      {
        name: '规格',
        con: 'spec'
      },
      {
        name: '型号',
        con: 'type'
      },
      {
        name: '单位',
        con: 'unit'
      }],
      cangkuu: [],
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      tiaoshu: 10,
      selectData: '',
      youce_xiangqing: false,
      sousuo_text: '',
      time: '',

      dangqian_xuanze_chanpin: [], // 当前所选产品
      zong_mingxi_num: '0',

      shifou_quling: false, // 是否去零

      chanpin_liebiao: [], // 产品列表
      zong_chanpin_liebiao: [], // 总产品列表

      // 产品选择修改选择样式    2020、11、11   啊哈哈  光棍节快乐啊QwQ
      chanpin: false,
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      prodNames: [],
      qiye_chanpin_leixing_tree: [], // 产品大类树
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_xuanze: '',
      chanpin_liebiao_list: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      dangqian_mingxi_kuang_chanpin: []
    }
  },
  mounted () {
  },
  components: { shaixuan },
  created () {
    this.prodName = ''
    this.prodId = ''
    this.mingxi_chanpin_liebiao()
    this.getCangku()
  },
  watch: {
    dangqian_yeshu () {
      // sessionStorage.setItem('cbr_yifen_pei', JSON.stringify(this.dangqian_yeshu))
      // this.liebiao_jiekou()
      if (this.zong_mingxi_num == 0) {
        
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      } else if (this.zong_mingxi_num == 1) {
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      }
    },
    tiaoshu () {
      if (this.zong_mingxi_num == 0) {
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      }
    },
    dangqian_xuanze_chanpin () {
      if (this.zong_mingxi_num == 0) {
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
        
      } else if (this.zong_mingxi_num == 1) {
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      }
    },
    zong_mingxi_num () {
      if (this.zong_mingxi_num == 0) {
        this.prod_name = []
        this.prodNames = this.zong_chanpin_liebiao
        this.biao1_tit = [{
          name: '日期',
          con: 'date_stock'
        },
        {
          name: '图片',
          con: 'prod_pic'
        },
        {
          name: '产品',
          con: 'prod_name'
        },
        {
          name: '产品编号',
          con: 'prod_code'
        },
        {
          name: '类型',
          con: 'cat_name'
        },
        {
          name: '规格',
          con: 'spec'
        },
        {
          name: '型号',
          con: 'type'
        },
        {
          name: '单位',
          con: 'unit'
        }]
        this.dangqian_yeshu = 1
        this.dangqian_xuanze_chanpin = []
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      } else if (this.zong_mingxi_num == 1) {
        this.prod_name = []
        this.prodNames = this.zong_chanpin_liebiao
        // {
        //     name:'摘要',
        //     con:'remarks',
        // },
        this.biao1_tit = [{
          name: '日期',
          con: 'date_stock'
        },
        {
          name: '图片',
          con: 'prod_pic'
        },
        {
          name: '产品',
          con: 'prod_name'
        },
        {
          name: '产品编号',
          con: 'prod_code'
        },
        {
          name: '仓库',
          con: 'site_name'
        },
        {
          name: '类型',
          con: 'cat_name'
        },
        {
          name: '规格',
          con: 'spec'
        },
        {
          name: '型号',
          con: 'type'
        },
        {
          name: '单位',
          con: 'unit'
        }]
        this.dangqian_yeshu = 1
        this.dangqian_zongshu = Number(this.chanpin_liebiao.length)
        this.dangqian_xuanze_chanpin = []
        if(this.time) {
          let time = this.time.split('-')
          this.liebiao_jiekou(time)
        } else {
          this.liebiao_jiekou()
        }
      }
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
        this.dangqian_mingxi_kuang_chanpin = []
      }
    }
  },
  methods: {
    async getCangku() {
      try{
        let param = {
          data: {
            ent_id: this.$ent_id()
          }
        }
        const res = await query_stock_mng_detail_site_list(param)
        console.log(res)
        if(res.data.code === '200') {
          let data = JSON.parse(res.data.body.data)
          this.cangku = data
        }
      }
      catch{}
    }, 
    hangdClick(i) {
      this.prodName = i.prod_name
      this.prodId = i.prod_id
      let year = ''
      let month = ''
      if(this.zong_mingxi_num == 0) {
        if(this.time) {
          this.gongying_shang_list = []
          year = this.time.split('-')[0].toString()
          month = this.time.split('-')[1].toString()
          console.log(month)
          query_stock_mng_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: i.prod_id, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: i.prod_name
              // page_size:this.tiaoshu+'',
              // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.gongying_shang_list = date
              this.dialogTableVisible = false
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        } else {
          this.gongying_shang_list = []
          let date = new Date()
          let year = date.getFullYear()
          let month = date.getMonth() + 1
          if (month >= 1 && month <= 9) {
            month = "0" + month;
          }
          query_stock_mng_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: i.prod_id, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: i.prod_name
              // page_size:this.tiaoshu+'',
              // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const data = JSON.parse(res.data.body.data)
              this.gongying_shang_list = data
              this.dialogTableVisible = false
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        }
      }
      if(this.zong_mingxi_num == 1) {
        if(this.time) {
          this.gongying_shang_list = []
          year = this.time.split('-')[0].toString()
          month = this.time.split('-')[1].toString()
          console.log(month)
          query_stock_mng_detail_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: i.prod_id, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: i.prod_name
              // page_size:this.tiaoshu+'',
              // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.gongying_shang_list = date
              this.dialogTableVisible = false
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        } else {
          this.gongying_shang_list = []
          let date = new Date()
          let year = date.getFullYear()
          let month = date.getMonth() + 1
          if (month >= 1 && month <= 9) {
            month = "0" + month;
          }
          query_stock_mng_detail_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: i.prod_id, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: i.prod_name
              // page_size:this.tiaoshu+'',
              // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const data = JSON.parse(res.data.body.data)
              this.gongying_shang_list = data
              this.dialogTableVisible = false
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        }
      }
    },
    chooseC() {
      this.dialogTableVisible = true
    },
    exportExcel() {
      let cangku = []
      let op = []
      let opp = []
      let year = ''
      let month = ''
      if(this.prod_name.length !== 0) {
        this.prodNames.forEach(ktem => {
          this.prod_name.forEach(item => {
            if(item == ktem.prod_name) {
              op.push(ktem.prod_id)
              opp.push(ktem.prod_name)
            }
          })
        })
      } else {
        this.prodNames.forEach(jtem => {
          op.push(jtem.prod_id)
          opp.push(jtem.prod_name)
        })
      }
      if(this.time){
        let year = this.time.split('-')[0].toString()
        let month = year = this.time.split('-')[1].toString()
      } else {
        let myDate = new Date()
        let year = myDate.getFullYear().toString()
        let month = (myDate.getMonth() + '1').toString()
      }
      if(this.site_name.length != 0) {
        this.site_name.forEach(item => {
          this.cangkuu.forEach(ktem => {
            if(item == ktem.site_name) {
              cangku.push(ktem.site_id)
            }
          })
        })
      }
      if(this.zong_mingxi_num == 0) {
        let date = ''
        date = this.url + 'export_stock_acct_gather?ent_id=' + this.$ent_id() + '&prod_ids=' + op.join(',') + '&search_str=' + opp.join(',') + '&year=' + year + '&month=' + month
        this.$func.multiDownLoad([date])
      } else {
        if(cangku) {
          let date = ''
          //date = this.url + 'export_supply_prod_qty_list?ent_id=' + this.$ent_id() + '&supply_id=' + this.gongying_shang_chanpin_liebiao[0].supply_id
          date = this.url + 'export_stock_acct_detail?ent_id=' + this.$ent_id() + '&prod_ids=' + op.join(',') + '&search_str=' + opp.join(',') + '&year=' + year + '&month=' + month + '&site_ids=' + cangku.join('.')
          this.$func.multiDownLoad([date])
        } else {
          let date = ''
          //date = this.url + 'export_supply_prod_qty_list?ent_id=' + this.$ent_id() + '&supply_id=' + this.gongying_shang_chanpin_liebiao[0].supply_id
          date = this.url + 'export_stock_acct_detail?ent_id=' + this.$ent_id() + '&prod_ids=' + op.join(',') + '&search_str=' + opp.join(',') + '&year=' + year + '&month=' + month
          this.$func.multiDownLoad([date])
        }
      }
    },
    chooseCk() {
      this.site_name = []
      this.dialogTableVisiblee = true
    },
    treeSuree() {
      let yu = []
      console.log(this.site_name)
      console.log(this.cangku)

      // this.gongying_shang_list.map(item => {
      //   if(item.site_name == this.site_name) {
      //     yu.push(item)
      //   }
      // })
      let prod_id = ''
      if(this.prodId == '' || this.prodId == null) {
        prod_id = this.zong_chanpin_liebiao[0].prod_id
      } else {
        prod_id = this.prodId
      }
      if(this.site_name.length != 0) {
        this.cangku.forEach(ktem => {
        this.site_name.forEach(item => {
          
            if(item == ktem.site_name) {
              yu.push(ktem.site_id)
            }
          })
        })
        console.log(this.cangkuu)
        let site_id = [...new Set(yu)]
        console.log(site_id)
        this.gongying_shang_list = []
        this.gongying_shang_list = yu
        this.dialogTableVisiblee = false
        let year = ''
        let month = ''
        if(this.time) {
          year = this.time.split('-')[0].toString()
          month = this.time.split('-')[1].toString()
        } else {
          let myDate = new Date()
          year = myDate.getFullYear().toString()
          month = (myDate.getMonth() + '1').toString()
        }
        query_stock_mng_detail_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            prod_id: prod_id, // 产品id
            year: year.toString(),
            month: month.toString(),
            search_str: this.prodName,
            site_ids: site_id.join(',')
          }
        })
        .then(res => {
          console.log(res)
          console.log(JSON.parse(res.data.body.data))
          this.gongying_shang_list = JSON.parse(res.data.body.data)
        })
      } else {
        this.gongying_shang_list = this.cangkuu
        this.dialogTableVisiblee = false
      }
    },
    treeSure() {
      console.log(this.prodNames)
      let op = []
      let year = ''
      let month = ''
      if(this.prod_name.length !== 0) {
        this.prodNames = this.zong_chanpin_liebiao
        this.prodNames.forEach(ktem => {
          this.prod_name.forEach(item => {
            if(item == ktem.prod_name) {
              op.push({
                prod_name: ktem.prod_name,
                prod_id: ktem.prod_id
              })
            }
          })
        })
        this.prodNames = op
        console.log(this.prodNames[0].id)
        if(this.time) {
          year = this.time.split('-')[0].toString()
          month = this.time.split('-')[1].toString()
        } else {
          let myDate = new Date()
          year = myDate.getFullYear().toString()
          month = (myDate.getMonth() + '1').toString()
        }
        if(this.zong_mingxi_num == 0) {
          query_stock_mng_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: this.prodNames[0].prod_id, // 产品id
              year: year,
              month: month,
              search_str: this.prodNames[0].prod_name
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            this.gongying_shang_list = []
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
            } else if (res.data.code == 500) {
              alert('系统报错')
            } else if(res.data.code === '400') {
              this.$message.info('您当前没有数据')
            }
          })
        } else {
          query_stock_mng_detail_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: this.prodNames[0].prod_id, // 产品id
              year: year,
              month: month,
              search_str: this.prodNames[0].prod_name
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
              this.cangkuu = date
              //this.gongying_shang_list = date
              console.log(this.gongying_shang_list)
              let op = []
              this.gongying_shang_list.forEach(item => {
                op.push({
                  name: item.prod_name,
                  site_name: item.site_name
                })
              })
              console.log(op)
              let list = op.filter((data,indx,self) => {
                return self.findIndex((data1) => data1.name == data.name)==indx;
              })
              list.map(item => {
                this.btn_chanpin.push(item.name)
              })
              list.forEach(item => {
                console.log(item.site_name.split(','))
                this.treeData.push({
                  label: item.site_name.split(',')[0],
                  id: item.site_name.split(',')[0],
                  children: [{
                    label: item.site_name.split(',')[1],
                    id: item.site_name.split(',')[1],
                    children: [{
                      label: item.name,
                      id: item.name
                    }]
                  }]
                })
              })
              console.log(list)
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        }
        console.log(op)
        
      } else {
        this.prodNames = this.zong_chanpin_liebiao
      }
      this.dialogTableVisible = false
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;font-size:0.14rem;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    hangdian (val) {
      console.log(val)
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    dianji_tiaoshu (val) {
      console.log(`每页 ${val} 条`)
      this.tiaoshu = val
      this.dangqian_yeshu = 1
    },
    liebiao_jiekou (time) {
      let pname = ''
      let pid = ''
      if(this.prodName && this.prodId) {
        pname = this.prodName
        pid = this.prodId
      }
      if(this.prodNames) {
        pname = this.prodNames[0].prod_name
        pid = this.prodNames[0].prod_id
      }
      if(this.zong_mingxi_num == 0) {
        if(time){
          var nian = time[0].toString()
          var yue = time[1].toString()
          this.gongying_shang_list = []
          query_stock_mng_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: pid, // 产品id
              year: nian,
              month: yue,
              search_str: pname
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            this.gongying_shang_list = []
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
            } else if (res.data.code == 500) {
              alert('系统报错')
            } else if(res.data.code === '400') {
              this.$message.info('您当前没有数据')
            }
          })
        } else {
          let myDate = new Date()
          let year = myDate.getFullYear()
          let month = myDate.getMonth() + '1'
          this.gongying_shang_list = []
          query_stock_mng_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: this.chanpin_liebiao.length != 0 ? this.chanpin_liebiao[Number(this.dangqian_yeshu) - 1].prod_id : null, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
              //this.gongying_shang_list = date
              console.log(this.gongying_shang_list)
              let op = []
              this.gongying_shang_list.forEach(item => {
                op.push({
                  name: item.prod_name,
                  site_name: item.site_name
                })
              })
              console.log(op)
              let list = op.filter((data,indx,self) => {
                return self.findIndex((data1) => data1.name == data.name)==indx;
              })
              list.map(item => {
                this.btn_chanpin.push(item.name)
              })
              list.forEach(item => {
                console.log(item.site_name.split(','))
                this.treeData.push({
                  label: item.site_name.split(',')[0],
                  id: item.site_name.split(',')[0],
                  children: [{
                    label: item.site_name.split(',')[1],
                    id: item.site_name.split(',')[1],
                    children: [{
                      label: item.name,
                      id: item.name
                    }]
                  }]
                })
              })
              console.log(list)
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        }
      } else {
        this.gongying_shang_list = []
        if(this.time) {
          let year = this.time.split('-')[0].toString()
          let month = this.time.split('-')[1].toString()
          query_stock_mng_detail_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: pid, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: pname
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
              this.cangkuu = date
              //this.gongying_shang_list = date
              console.log(this.gongying_shang_list)
              let op = []
              this.gongying_shang_list.forEach(item => {
                op.push({
                  name: item.prod_name,
                  site_name: item.site_name
                })
              })
              console.log(op)
              let list = op.filter((data,indx,self) => {
                return self.findIndex((data1) => data1.name == data.name)==indx;
              })
              list.map(item => {
                this.btn_chanpin.push(item.name)
              })
              list.forEach(item => {
                console.log(item.site_name.split(','))
                this.treeData.push({
                  label: item.site_name.split(',')[0],
                  id: item.site_name.split(',')[0],
                  children: [{
                    label: item.site_name.split(',')[1],
                    id: item.site_name.split(',')[1],
                    children: [{
                      label: item.name,
                      id: item.name
                    }]
                  }]
                })
              })
              console.log(list)
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        } else {
          let myDate = new Date()
          let year = myDate.getFullYear()
          let month = myDate.getMonth() + '1'
          this.gongying_shang_list = []
          query_stock_mng_detail_list({
            data: {
              ent_id: this.$ent_id(),
              user_id: this.$jichuxinxi().user_id,
              prod_id: this.chanpin_liebiao.length != 0 ? this.chanpin_liebiao[Number(this.dangqian_yeshu) - 1].prod_id : null, // 产品id
              year: year.toString(),
              month: month.toString(),
              search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null
            // page_size:this.tiaoshu+'',
            // page_index:this.dangqian_yeshu+'',
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date, 'asdasdasdasdas')
              // if(date.list.length!=0){
              //     this.dangqian_zongshu=date.total
              //     this.gongying_shang_list=date.list
              // }else{
              //     this.dangqian_zongshu=0
              //     this.gongying_shang_list=[]
              // }
              this.gongying_shang_list = date
              this.cangkuu = date
              //this.gongying_shang_list = date
              console.log(this.gongying_shang_list)
              let op = []
              this.gongying_shang_list.forEach(item => {
                op.push({
                  name: item.prod_name,
                  site_name: item.site_name
                })
              })
              console.log(op)
              let list = op.filter((data,indx,self) => {
                return self.findIndex((data1) => data1.name == data.name)==indx;
              })
              list.map(item => {
                this.btn_chanpin.push(item.name)
              })
              list.forEach(item => {
                console.log(item.site_name.split(','))
                this.treeData.push({
                  label: item.site_name.split(',')[0],
                  id: item.site_name.split(',')[0],
                  children: [{
                    label: item.site_name.split(',')[1],
                    id: item.site_name.split(',')[1],
                    children: [{
                      label: item.name,
                      id: item.name
                    }]
                  }]
                })
              })
              console.log(list)
            } else if (res.data.code == 500) {
              alert('系统报错')
            }
          })
        }
      }
    },
    fujian_xiazai (i) {
      this.$func.fujian_xiazai2(i)
    },
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    // 点击选择按钮
    dianji_xuanze (i) {
      if (i.id == 1) {
        this.$router.push('/disc')
      }
    },
    // 点击选择产品
    dianji_xuanze_chanpin () {
      // this.chanpin_dalei(null)
      this.chanpin = true
      query_ent_prod_cat_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.qiye_chanpin_leixing_tree = date
        } else if (res.data.code == 500) {}
      })
      query_prod_info_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          cat_id: '0',
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          this.no_xuan_chanpin(date)
        } else if (res.data.code == 10140) {
        }
      })
      this.dangqian_mingxi_kuang_chanpin = this.dangqian_xuanze_chanpin
      console.log(this.dangqian_mingxi_kuang_chanpin, 'qweqwes')
    },
    chanpin_dalei (parent_id, yixuan) {
      if (yixuan != undefined && yixuan != null) {
      } else {
        yixuan = this.dangqian_xuanze_chanpin
      }
      query_category_info_list({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          parent_id: parent_id != null ? parent_id : '0',
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10193) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_xiaolei(parent_id, date, yixuan)
        } else if (res.data.code == 10194) {}
      })
    },
    chanpin_xiaolei (parent_id, parent, yixuan) {
      query_prod_info_list({
        data: {
          active: '1',
          cat_id: parent_id != null ? parent_id : '0',
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10139) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.xuanze.jichu('选择产品', parent, date, yixuan, 'cat_name', 'cat_id', 'prod_name', 'prod_id')
          this.$refs.xuanze.dan_duoxuan('单选')
          this.$refs.xuanze.bangding('dangqian_xuanze_chanpin')
        } else if (res.data.code == 10140) {}
      })
    },
    sousuo_chanpin (text) {
      console.log(text)
      get_prod_list_like({
        data: {
          active: '1',
          ent_id: this.$ent_id(),
          prod_name: text.length != 0 ? text : null,
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10031) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$refs.xuanze.sousuo_xiaolei(date, 'prod_name', 'prod_id')
        } else if (res.data.code == 10032) {}
      })
    },
    // 点击大类
    dianji_dalei (data) {
      this.chanpin_dalei(data.id, data.yixuan)
    },
    dianji_queding (val) {
      console.log(val)
      this[val.ziduan] = val.list
      if (val.list.length != 0) {
        this.chanpin_liebiao = this.dangqian_xuanze_chanpin
      } else {
        this.chanpin_liebiao = JSON.parse(JSON.stringify(this.zong_chanpin_liebiao))
      }
    },

    // 明细接口
    mingxi_jiekou () {
      // this.gongying_shang_list=[
      //     {
      //         aa:'111',
      //         bb:'222'
      //     },
      //     {
      //         aa:'22',
      //         bb:'33'
      //     },
      //     {
      //         aa:'33',
      //         bb:'44'
      //     },
      // ]
      query_stock_mng_detail_list({
        data: {
          ent_id: this.$ent_id(),
          prod_id: this.chanpin_liebiao.length != 0 ? this.chanpin_liebiao[Number(this.dangqian_yeshu) - 1].prod_id : null, // 产品id
          year: this.time.split('-')[0],
          month: this.time.split('-')[1],
          search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null
        // page_size:this.tiaoshu+'',  你可以不用啊
        // page_index:this.chanpin_liebiao[Number(this.dangqian_yeshu)-1].prod_id,
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.gongying_shang_list = date
        } else if (res.data.code == 500) {}
      })
    },
    mingxi_chanpin_liebiao () {
      query_stock_mng_detail_prod_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(JSON.parse(res.data.body.data),'asdasdasdadasdd')
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.chanpin_liebiao = JSON.parse(JSON.stringify(date))
          this.zong_chanpin_liebiao = JSON.parse(JSON.stringify(date))
          this.prodNames = JSON.parse(JSON.stringify(date))
          console.log(this.zong_chanpin_liebiao)
          this.liebiao_jiekou()
        } else if (res.data.code == 500) {}
      })
    },
    wuyong () {},
    // hebing ({ row, column, rowIndex, columnIndex }) {
    //   console.log(row)
    //   // if(row.flag_sum!=undefined&&row.flag_sum=='1'){
    //   //     if(columnIndex==0){
    //   //         return [1,10]
    //   //     }else if(columnIndex ==10){
    //   //         return [0,0]
    //   //     }

    //   // }
    //   if (row.flag_sum != undefined && row.flag_sum == '1') {
    //     if (columnIndex === 0) {
    //       return [1, 9]
    //     } else if (columnIndex === 1 ||
    //                 columnIndex === 2 ||
    //                 columnIndex === 3 ||
    //                 columnIndex === 4 ||
    //                 columnIndex === 5 ||
    //                 columnIndex === 6 ||
    //                 columnIndex === 7 ||
    //                 columnIndex === 8
    //     ) {
    //       return [0, 0]
    //     }
    //   }
    // },
    // quling (i, date, num) {
    //   if (this.zong_mingxi_num == 0) {
    //     if (this.shifou_quling) {
    //       if (Number(num) == 0 || num == undefined) {
    //         return ''
    //       } else {
    //         return Number(num).toFixed(2)
    //       }
    //     } else {
    //       if (num == undefined) {
    //         return 0
    //       } else {
    //         return Number(num).toFixed(2)
    //       }
    //     }
    //   } else {
    //     if (i == '余额') {
    //       if (date.flag_sum != undefined && date.flag_sum == '1') {

    //       } else {
    //         if (this.shifou_quling) {
    //           if (Number(num) == 0 || num == undefined) {
    //             return ''
    //           } else {
    //             return Number(num).toFixed(2)
    //           }
    //         } else {
    //           if (num == undefined) {
    //             return 0
    //           } else {
    //             return Number(num).toFixed(2)
    //           }
    //         }
    //       }
    //     } else {
    //       if (this.shifou_quling) {
    //         if (Number(num) == 0 || num == undefined) {
    //           return ''
    //         } else {
    //           return Number(num).toFixed(2)
    //         }
    //       } else {
    //         if (num == undefined) {
    //           return 0
    //         } else {
    //           return Number(num).toFixed(2)
    //         }
    //       }
    //     }
    //   }
    // },
    no_xuan_chanpin (date) {
      this.chanpin_liebiao_list = date
      console.log(date, 'quhdouqwdwq')
      this.$forceUpdate()
    },
    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.chanpin_liebiao_list=deal(a,b,'prod_id')
            // console.log(this.chanpin_liebiao_list)
            // this.$forceUpdate()
            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              console.log(this.chanpin_liebiao_list, 'dasgkgkg')
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach(item => {
                  this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                    if (ite.prod_id == item.prod_id) {
                      console.log(item)
                      this.$refs.Tablea.toggleRowSelection(item)
                    }
                  })
                })
              })
            }
            this.$forceUpdate()
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.dangqian_mingxi_kuang_chanpin)
      console.log(this.suoxuan_list)
      this.dangqian_xuanze_chanpin = this.dangqian_mingxi_kuang_chanpin
      this.chanpin = false
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },

    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    },
    xuanze_chanpin (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    },
    // qvLing() {
    //   if(this.shifou_quling == false) {
    //     this.shifou_quling = true
    //     this.gongying_shang_list.forEach((item, index) => {
    //       if(item.amt_end == "0.00"){
    //         this.indexZero.push(index)
    //       }
    //     })
    //     this.indexZero.forEach(k => {
    //       delete this.gongying_shang_list[k]
    //     })
    //   } else {
    //     this.indexZero = []
    //     this.shifou_quling = false
    //     this.liebiao_jiekou()
    //   }
    // },
    handleDate() {
      let time = this.time.split('-')
      this.liebiao_jiekou(time)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../chanpin.scss';
 @import '../../../style/salesman.scss';
 .opt{
   display: flex;
   justify-content: space-around;
   padding-top: 10px;
   .btn_chan{
    width: 120px;
    height: 30px;
    background-color: #8041d8;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
 }
 }
 .daochu{
   height: 0.4rem;
   padding: 0 0.2rem;
   border-radius: 0.08rem;
   line-height: 0.4rem;
   text-align: center;
   cursor: pointer;
   background: #8140DA;
   color: #fff;
   border: 1px solid #979797;
   margin-right: 0.3rem;
 }
</style>
