<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-30 18:04:41
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="duoxuan" v-if="zhanshi">
        <div class="duoxuan_box" @click.stop>
            <p class="beijing_box_tit">{{tit_text}}</p>
            <div class="beijing_box_con">
                <div>
                    <p class="beijing_box_con_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" v-model='sousuo_text' @keyup.enter="sousuo">
                        <i class="el-icon-circle-close" @click="sousuo_text='';sousuo()"></i>
                    </p>
                    <ul class="mianbaoxie">
                        <li @click="dianji_mianbao_xie(i,index)" v-for="(i,index) in mianbao_xie" :class="index==mianbao_xie.length-1?'huise':''" :key="i.id">{{i.name}}&nbsp;></li>
                    </ul>
                    <p v-if="zhanshi_list.length!=0&&dan_duoxuan_zhi==2" class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                    <ul class="renyuan_xuanze_list">
                        <li class="renyuan_xuanze_list_li" v-for="i in jiegou_list" :key="i.id" @click="dianji_dalei(i)">
                            <!-- <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')"> -->
                            {{i.name}}
                        </li>
                        <li class="renyuan_xuanze_list_li" v-for="i in zhanshi_list" :key="i.id" @click="xuanze_danxuan(i)">
                            <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                        </li>
                    </ul>
                </div>
                <div>
                    <ul class="renyuan_xuanze_list">
                        <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                            {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu(i,index)" src="../../assets/guanbi_zhu.png">
                        </li>
                    </ul>
                </div>
            </div>
            <p class="beijing_box_foot">
                <span @click="dianji_lie_queding">确定</span>
                <span @click="zhanshi=false">取消</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'duoxuan',

  data () {
    return {
      tit_text: '', // 标题
      xianshi_quanbu: false,
      jiegou_list: [], // 大类
      xianshi_xiang_list: [], // 已经选择de小类
      zhanshi_list: [], // 小类
      sousuo_text: '',

      dalei_id: '', // 大类的id
      dalei_name: '', // 大类name
      xiaolei_id: '', // 小类id
      xiaolei_name: '', // 小类name
      mianbao_xie: [
        {
          name: this.$jichuxinxi().ent_info.ent_name,
          id: '0'
        }
      ], // 面包屑list

      zhanshi: false, // 打开当前页面

      bangding_ziduan: '', /// 绑定字段

      dan_duoxuan_zhi: 2 // 1单选  2多选
    }
  },
  watch: {
    zhanshi () {
      if (!this.zhanshi) {
        this.sousuo_text = ''
        this.mianbao_xie = []
        this.mianbao_xie.push(
          {
            name: this.$jichuxinxi().ent_info.ent_name,
            id: '0'
          }
        )
      }
    }
  },
  created () {
        
  },
  mounted () {},
  methods: {
    bangding (ziduan) {
      this.bangding_ziduan = ziduan
    },
    jichu (biaoti, dalei, xiaolei, yixuan, dalei_name, dalei_id, xiaolei_name, xiaolei_id) {
      this.tit_text = biaoti
      this.zhanshi = true
      this.xiaolei_id = xiaolei_id
      this.xiaolei_name = xiaolei_name
      if (dalei != null) {
        dalei.forEach(item => {
          this.$set(item, 'name', item[dalei_name])
          this.$set(item, 'id', item[dalei_id])
        })
      }
      if (xiaolei != null) {
        xiaolei.forEach(item => {
          this.$set(item, 'name', item[xiaolei_name])
          this.$set(item, 'id', item[xiaolei_id])
          yixuan.forEach(ite => {
            if (ite[xiaolei_id] == item.id) {
              this.$set(item, 'zhi', true)
            }
          })
          if (item.zhi == undefined) {
            this.$set(item, 'zhi', false)
          }
        })
      }
      if (yixuan != null) {
        yixuan.forEach(item => {
          this.$set(item, 'name', item[xiaolei_name])
          this.$set(item, 'id', item[xiaolei_id])
        })
      }
      this.jiegou_list = dalei != null ? dalei : []
      this.zhanshi_list = xiaolei != null ? xiaolei : []
      this.xianshi_xiang_list = yixuan != null ? JSON.parse(JSON.stringify(yixuan)) : []
      setTimeout(() => {
        console.log(this.zhanshi_list)
        this.jiancha_xuanzhong()
        this.jiancha_shifou_quanxuan()
      }, 500)
    },
    // 搜索
    sousuo () {
      this.$emit('sousuo', {
        text: this.sousuo_text,
        bangding_ziduan: this.bangding_ziduan
      })
    },
    sousuo_xiaolei (xiaolei, name, id) {
      xiaolei.forEach(item => {
        this.$set(item, 'name', item[name])
        this.$set(item, 'id', item[id])
        this.$set(item, 'zhi', false)
      })
      this.zhanshi_list = xiaolei
      this.jiancha_xuanzhong()
      this.jiancha_shifou_quanxuan()
    },
    dianji_mianbao_xie (i, index) {
      this.sousuo_text = ''
      if (this.mianbao_xie.length != 1) {
        this.$emit('dalei', {
          id: i.id,
          yixuan: this.xianshi_xiang_list
        })
        this.mianbao_xie.splice(index + 1)
      }
    },
    // 选择
    xuanze_danxuan (i) {
      if (this.dan_duoxuan_zhi == 2) {
        i.zhi = !i.zhi
        if (i.zhi) {
          this.xianshi_xiang_list.push(i)
        } else {
          for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
            if (this.xianshi_xiang_list[a].id == i.id) {
              this.xianshi_xiang_list.splice(a, 1)
            }
          }
        }
        this.jiancha_shifou_quanxuan()
      } else {
        if (this.xianshi_xiang_list.length == 1) {
          if (this.xianshi_xiang_list[0].id == i.id) {
            i.zhi = false
            this.xianshi_xiang_list = []
          } else {
            this.$message({
              message: '请单选',
              type: 'warning'
            })
          }
        } else {
          i.zhi = !i.zhi
          this.xianshi_xiang_list.push(i)
        }
      }
    },
    // 检查是否全选
    jiancha_shifou_quanxuan () {
      this.xianshi_quanbu = true
      this.zhanshi_list.forEach(item => {
        if (!item.zhi) {
          this.xianshi_quanbu = false
        }
      })
    },
    // 点击全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      this.zhanshi_list.forEach(item => {
        if (this.xianshi_quanbu) {
          this.xianshi_xiang_list.push(item)
          this.zhanshi_list.forEach(item => {
            this.$set(item, 'zhi', true)
          })
        } else {
          for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
            if (this.xianshi_xiang_list[a].id == item.id) {
              this.xianshi_xiang_list.splice(a, 1)
            }
          }
          this.zhanshi_list.forEach(item => {
            this.$set(item, 'zhi', false)
          })
        }
      })
      this.xianshi_xiang_list = this.$func.Es5duplicate(this.xianshi_xiang_list, 'id')
    },
    jiancha_xuanzhong () {
      this.xianshi_xiang_list.forEach(item => {
        this.zhanshi_list.forEach(ite => {
          if (item.id == ite.id) {
            ite.zhi = true
          }
        })
      })
    },
    // 点击删除
    dianji_shanchu (i, index) {
      const shanchu_zhi = JSON.parse(JSON.stringify(i))
      this.zhanshi_list.forEach(item => {
        if (item.id == shanchu_zhi.id) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
      this.jiancha_shifou_quanxuan()
    },
    // 点击确定
    dianji_lie_queding () {
      this.xianshi_xiang_list.forEach(item => {
        this.$set(item, this.xiaolei_id, item.id)
        this.$set(item, this.xiaolei_name, item.name)
      })
      this.$emit('shaixuan_queding', {
        list: this.xianshi_xiang_list,
        ziduan: this.bangding_ziduan
      })
      this.zhanshi = false
    },
    dianji_dalei (i) {
      console.log(i)
      this.sousuo_text = ''
      // this.$refs.chanpin_dalei(i.id)
      this.$emit('dalei', {
        id: i.id,
        yixuan: this.xianshi_xiang_list
      })
      this.mianbao_xie.push({
        name: i.name,
        id: i.id
      })
    },
    dan_duoxuan (text) {
      if (text == '单选') {
        this.dan_duoxuan_zhi = 1
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.duoxuan{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index:100;
    background: rgba(0,0,0,0.5);
    .duoxuan_box{
        min-height:0.58rem;
        width:9rem;
        background:#fff;
        position: relative;
        top:1.2rem;
        left:50%;
        margin-left: -4.5rem;
        border-radius: 0.04rem;
        margin-bottom: 1.4rem;
        .beijing_box_tit{
            font-size: 0.2rem;
            color:#1A2533;
            font-weight: bold;
            text-align: center;
            padding: 0.38rem 0;
        }
        .beijing_box_con{
            display: flex;
            // align-items: center;
            justify-content: center;
            div{
                height: 4.56rem;
                width:3.33rem;
                position: relative;
                background: #e7e8eb;
                margin: 0 0.13rem;
                overflow-y:auto;
                &::-webkit-scrollbar {
                    width: 0.2rem;
                    height: 0.08rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 0.12rem;
                    border: 0.06rem solid rgba(0, 0, 0, 0);
                    box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                }
                
                &::-webkit-scrollbar-thumb:hover {
                    box-shadow: 0.08rem 0 0 #4A4A4A inset;
                }
                .beijing_box_con_sousuo{
                    padding: 0.08rem 0.26rem;
                    position: relative;
                    display: flex;
                    align-items: center;
                    input{
                        width:2.2rem;
                        padding: 0 0.3rem;
                        height:0.3rem;
                        border-radius: 0.15rem;
                        border:1px solid #ccc;
                        outline:none;
                    }
                    i{
                        font-size: 0.16rem;
                        position: absolute;
                        cursor: pointer;
                    }
                    .el-icon-search{
                        left:0.35rem;
                    }
                    .el-icon-circle-close{
                        right:0.35rem;
                        display: none;
                    }
                    &:hover{
                        .el-icon-circle-close{
                            display: block;
                        }
                    }
                }
                .mianbaoxie{
                    padding: 0.08rem 0.26rem;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    color:#9a86db;
                    cursor: pointer;
                    li{
                        font-size: 0.12rem;
                    }
                    .huise{
                        color: #4A4A4A;
                    }
                }
                .beijing_box_con_quanbu{
                    padding: 0.08rem 0.26rem;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background: #d4caca;
                    }
                    img{
                        height:0.16rem;
                        width:0.16rem;
                        margin-right: 0.08rem;
                    }
                }
                .renyuan_xuanze_list{
                    .renyuan_xuanze_list_li{
                        display: flex;
                        align-items: center;
                        padding: 0.08rem 0.26rem;
                        cursor: pointer;
                        &:hover{
                            background: #d4caca;
                        }
                        .xuanxiang_kuang{
                            height:0.16rem;
                            width:0.16rem;
                            margin-right: 0.08rem;
                        }
                        .shanchu_xianshi{
                            height:0.12rem;
                            width:0.12rem;
                        }
                        position: relative;
                        ul{
                            position: absolute;
                            top:0.26rem;
                            left:0.27rem;
                            background-color: #fff;
                            width: 1.57rem;
                            height:1rem;
                            border-radius: 0.03rem;
                            padding: 0.05rem;
                            overflow-y:auto;
                            opacity: 1;
                            z-index: 101;
                            &::-webkit-scrollbar {
                                width: 0.2rem;
                                height: 0.08rem;
                            }
                            
                            &::-webkit-scrollbar-thumb {
                                border-radius: 0.12rem;
                                border: 0.06rem solid rgba(0, 0, 0, 0);
                                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                            }
                            
                            &::-webkit-scrollbar-thumb:hover {
                                box-shadow: 0.08rem 0 0 #4A4A4A inset;
                            }
                            li{
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                padding: 0.05rem 0;
                                &:hover{
                                    background: #b9b7b9;
                                }
                                img{
                                    height:0.16rem;
                                    width:0.16rem;
                                    margin-left: 0.1rem;
                                }
                            }
                        }
                    }
                }
                &:nth-child(2){
                    ul{
                        li{
                            justify-content: space-between;
                        }
                    }
                }
            }
        }
        .beijing_box_foot{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:0.37rem;
            padding-bottom:0.35rem ;
            span{
                padding: 0.14rem 0.27rem;
                border-radius: 0.04rem;
                font-size: 0.14rem;
                margin: 0 0.28rem;
                cursor: pointer;
                &:nth-child(1){
                    background: #9a86db;
                    color:#fff;
                }
                &:nth-child(2){
                    border:0.01rem solid #888;
                    color:#909090;
                }
                
            }
        }
    }
}
</style>
